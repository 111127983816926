import React from 'react'
import axios from 'axios'
import { withFormik, Form, Field } from 'formik'
import addToMailchimp from 'gatsby-plugin-mailchimp'

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

/** constants */
const wrapperClass = 'pb-24 lg:pb-36'
const msgSuccess = 'Thanks, we’ll be in touch soon.'
const msgError = 'There was an error submitting the form, please try again.'

const Success = () => (
  <div className={wrapperClass}>
    <div className="grid mb-8">
      <div className="grid__half--secondary">
        {/* Success Message */}
        <div className="mt-6 text-yellow lg:type-lg"><h4>{msgSuccess}</h4></div>
      </div>
    </div>
  </div>
)

const FormWithError = ({ isSubmitting, errors, values, success }) => (
  <>
    {!success && 
    <div className="mb-10">
      <div className="grid">
        <div className="grid__half--secondary">
          <div className="w-full text-yellow pt-6 lg:type-lg">
            <h4>To sign up to our e-newsletter please complete the form below and tick the box to receive our updates directly into your inbox:</h4>
          </div>
        </div>
      </div>
    </div>
    }
    <Form data-netlify="true" name="contact" className={wrapperClass}>
      <div className="grid mb-2">
        <div className="grid__half--secondary">
          {/* First & Last Name */}
          <div className="flex justify-between -mr-2 lg:mr-0 lg:pb-5">
            <div className="w-1/2 lg:w-11/23 pr-2 lg:pr-0 pb-2 lg:pb-0">
              <Field
                id="firstName"
                name="firstName"
                type="text"
                className="w-full px-2 py-3"
                placeholder="First name*"
                required
              />
            </div>
            <div className="w-1/2 lg:w-11/23 pr-2 lg:pr-0 pb-2 lg:pb-0">
              <Field
                id="lastName"
                name="lastName"
                type="text"
                className="w-full px-2 py-3"
                placeholder="Last name*"
                required
              />
            </div>
          </div>
          {/* Email */}
          <div className="lg:flex justify-between lg:pb-5">
            <div className="lg:w-full pb-2 lg:pb-0">
              <Field
                id="email"
                name="email"
                type="email"
                className="w-full px-2 py-3"
                placeholder="Email address*"
                required
              />
            </div>
          </div>
          {/* Enquiry Type */}
          <div className="lg:flex justify-between lg:pb-5">
            <div className="lg:w-full pb-2 lg:pb-0">
              <Field component="select"
                id="enquiryType"
                name="enquiryType"
                type="dropdown"
                className={FormikForm.enquiryTypeEntered ? 'w-full px-2 py-3 contact-dropdown-filled' : 'w-full px-2 py-3 contact-dropdown-placeholder'}
                /*className="w-full px-2 py-3 contact-dropdown-placeholder"*/
                placeholder="Enquiry Type*"
                required>
                <option value="" defaultValue>Enquiry Type</option>
                <option value="Investor Enquiry">Investor Enquiry</option>
                <option value="Employment Enquiry">Employment Enquiry</option>
                <option value="Media Enquiry">Media Enquiry</option>
                <option value="Other">Other</option>
              </Field>
            </div>
          </div>
          {/* Message */}
          <div className="w-full pb-2 lg:pb-0">
            <Field
              id="message"
              name="message"
              component="textarea"
              placeholder="Message*"
              className="w-full h-48 px-2 py-3"
              required
            />
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="grid__half--secondary">
          <div className="mb-12">
            <label htmlFor="wantsUpdates">
              <Field
                id="wantsUpdates"
                name="wantsUpdates"
                type="checkbox"
                className="px-2 py-3 mr-4"
              />
              <span className="mb-4 text-white text-sm">
                I also want to receive Puro updates direct to my inbox.
              </span>
            </label>
          </div>
          <input
            className="lg:type-xl link link--white bg-transparent underline"
            type="submit"
            values="Submit"
            disabled={isSubmitting}
          />
          {/* Error Message */}
          {errors.submit && <div className="text-white mt-2">{msgError}</div>}
        </div>
      </div>
    </Form >
  </>
  
)


const DefaultForm = props => {

  if (props.values.enquiryType != '') {
    FormikForm.enquiryTypeEntered = true

  } else {
    FormikForm.enquiryTypeEntered = false
  }

  const { status } = props

  if (status && status.success) {
    return <Success />
  } else {
    return <FormWithError {...props} />
  }
}
const FormikForm = withFormik({
  mapPropsToValues: values => ({
    firstName: values.firstName || '',
    lastName: values.lastName || '',
    email: values.email || '',
    enquiryType: values.enquiryType || '',
    message: values.message || '',
    wantsUpdates: values.wantsUpdates ? true : false,
  }),
  handleSubmit: async (
    form,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {

      if ( form.wantsUpdates === true) {
        try {

          const apiUrl = 'https://puro.co.nz/.netlify/functions/campaign-monitor';   
          //const apiUrl = 'http://localhost:21189/.netlify/functions/campaign-monitor'; 
                      
          let data = await(JSON.stringify({name: form.firstName + ' ' + form.lastName, emailAddress: form.email, list: 'newsletter-signup'}));      
    
          await fetch(apiUrl, {method:'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
            body: data
          })
          .then(response => {
            
            response.json().then((data) => {
              console.log(data);
            })
            
          });
    
          //resetForm()
          setStatus({ success: true })
          
        } catch (error) {

          console.log('Caught inner error' , error);
          // Set errors.submit to TRUE
          setErrors({
            submit: true,
          })
          // Reset error.submit to FALSE
          setTimeout(() => {
            setErrors({ submit: false })
          }, 5000)

        } finally {
          setSubmitting(false)
        }

      }

      //doesn't want updates
    
      console.log('posting form');
      await axios.post(
        '?no-cache=1',
        encode({ 'form-name': 'contact', ...form }),
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      )

      resetForm()
      setStatus({ success: true })


    } catch (error) {

      console.log('Caught outer error');
      // Set errors.submit to TRUE
      setErrors({ submit: true })
      // REset error.submit to FALSE
      setTimeout(() => {
        setErrors({ submit: false })
      }, 5000)
    } finally {
      setSubmitting(false)
    }
  },
})(DefaultForm)



export default FormikForm
