import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import Form from '../components/Form/Contact'

const ContactPage = ({ data, location }) => {
  const page = data.prismicContact.data
  return (
    <Layout bg="bg-charcoal">
      <SEO
        title={page.meta_title}
        description={page.meta_description}
        location={location}
      />
      <div className="pt-32 lg:pt-36">
        <div className="grid">
          <div className="grid__half--primary">
            <h1 className="type-lg lg:type-3xl text-white">{page.title}</h1>
          </div>
          <div className="grid__half--secondary text-white lg:type-lg rte rte--white pt-4">
            <div
              className="w-full rte"
              dangerouslySetInnerHTML={{ __html: page.body.html }}
            />
          </div>
        </div>
      </div>
      <Form />
    </Layout>
  )
}

ContactPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const pageContactQuery = graphql`
  query {
    prismicContact {
      data {
        title
        body {
          html
          text
        }
        final_text_line{
          html
          text
        }
        meta_title
        meta_description
      }
    }
  }
`

export default ContactPage
